<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />

        <div class="flex flex-col items-center z-1">
            <a href="/">
                <img
                    src="../../../../resources/images/logo-white.png"
                    alt="Logo"
                    class="h-auto mb-2 guest-logo"
                />
            </a>

            <div v-if="status" class="mb-4 text-sm font-medium text-green-600 z-1">
                {{ status }}
            </div>

            <form @submit.prevent="submit" class="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
                <div>
                    <InputLabel for="email" value="Email" class="text-gray-800" />

                    <TextInput
                        id="email"
                        type="email"
                        class="mt-1 block w-full border-gray-300 focus:border-gray-500 focus:ring-gray-500 text-black"
                        v-model="form.email"
                        required
                        autofocus
                        autocomplete="username"
                    />

                    <InputError class="mt-2 text-red-500" :message="form.errors.email" />
                </div>

                <!-- Password Field -->
                <div class="mt-4">
                    <InputLabel for="password" value="Password" class="text-gray-800" />

                    <TextInput
                        id="password"
                        type="password"
                        class="mt-1 block w-full border-gray-300 focus:border-gray-500 focus:ring-gray-500 text-black"
                        v-model="form.password"
                        required
                        autocomplete="current-password"
                    />

                    <InputError class="mt-2 text-red-500" :message="form.errors.password" />
                </div>

                <!-- Remember Me Checkbox -->
                <div class="mt-4 block">
                    <label class="flex items-center">
                        <Checkbox name="remember" v-model:checked="form.remember" />
                        <span class="ms-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <!-- Forgot Password and Log In Button -->
                <div class="mt-6 flex flex-col items-center">
                    <PrimaryButton
                        class="w-full mb-4 bg-green-600 text-white hover:bg-green-700 focus:bg-green-700 py-3 flex justify-center items-center"
                        :class="{ 'opacity-50': form.processing }"
                        :disabled="form.processing"
                    >
                        Log in
                    </PrimaryButton>

                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="text-sm mt-2 text-gray-600 hover:text-gray-800 underline focus:outline-none"
                    >
                        Forgot your password?
                    </Link>
                    <Link
                        :href="route('register')"
                        class="text-sm mt-2 text-gray-600 hover:text-gray-800 underline focus:outline-none"
                    >
                        Don't have an account? Register here
                    </Link>
                </div>
            </form>
        </div>
    </GuestLayout>
</template>

<style>
/* Custom styles to maintain consistency */
form {
    max-width: 100%;
    width: 100%;
    padding: 16px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

input[type="email"],
input[type="password"] {
    color: #000000;
}

.guest-logo {
    height: 30px;
    margin-bottom: 30px;
}


</style>
